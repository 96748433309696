<template>
    <div class="custom-pagination">
        <button :disabled="currentPage === 1" @click="changePage(currentPage - 1)"><</button>
        <!-- <span v-for="page in displayedPages" :key="page">
            <button :class="{ active: page === currentPage }" @click="changePage(page)">
                {{ page }}
            </button>
        </span> -->
        <button :disabled="currentPage === totalPages" @click="changePage(currentPage + 1)">></button>
        <span class="page-info">{{ currentPage }} / {{ totalPages }} {{ lang=='en'?'pages':'页' }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'CustomPagination',
    props: {
        totalItems: {
            type: Number,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 10,
        },
        maxDisplayedPages: {
            type: Number,
            default: 5,
        },
    },
    data() {
        return {
            currentPage: 1,
        };
    },
    computed: {
        ...mapState({
            lang: state => state.lang,
        }),
        totalPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage);
        },
        displayedPages() {
            const half = Math.floor(this.maxDisplayedPages / 2);
            let start = this.currentPage - half;
            let end = this.currentPage + half;

            if (start < 1) {
                start = 1;
                end = Math.min(this.maxDisplayedPages, this.totalPages);
            }

            if (end > this.totalPages) {
                end = this.totalPages;
                start = Math.max(1, this.totalPages - this.maxDisplayedPages + 1);
            }

            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
    },
    methods: {
        changePage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
                this.$emit('page-change', page);
            }
        },
    },
};
</script>

<style scoped>
.custom-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    margin: 0 5px;
    border-radius: 4px;
    padding: 5px 20px;
    border: 1px solid #ddd;
    /* background-color: #fff; */
    cursor: pointer;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

button.active {
    background-color: #1890ff;
    color: #fff;
    border-color: #1890ff;
}

.page-info {
    margin-left: 10px;
}
</style>

<template>
  <div class="empty" v-if="data.length<1">
    <img src="@/assets/img/default/empty.png" alt />
    <span>{{description || '暂无数据'}}</span>
    <slot></slot>
  </div>
</template>

<script>
    export default {
      name: "empty",
      props:{
        /**
         * 列表的数据
         */
        data:Array,
        description:{
          type:String,
          default:'',
        },
      }
    }
</script>
<style scoped lang="scss">
  .empty {
    box-sizing: border-box;
    text-align: center;
    padding-top: 100px ;
    background: none;
    img {
      width: 90px;
      padding-bottom: 15px;
      margin: 0 auto;
    }
    span {
      position: relative;
      display: block;
      top: 0;
      color: #B5B5B5;
      font-size: 14px;
    }
  }
</style>

<template>
	<a-modal centered :visible="visible" :closable="false" :maskClosable="true" @cancel="afterClose" :footer="null"
		wrapClassName="blur-modal" :maskStyle="{ backdropFilter: 'blur(16px)' }">
		<div class="claim-modal md:p-24">
			<div class="close md:w-24 md:p-0" @click="afterClose">
				<img src="https://s3.us-west-1.amazonaws.com/agi.love/img/close.svg" alt="">
			</div>
			<div class="claim-content md:flex-col">
				<div class="claim-img md:hidden">
					<img :src="item.icon" alt="">
				</div>
				<div class="claim-img-md md:block md:mx-auto mb-32">
					<img :src="item.icon" alt="">
				</div>
				<div class="claim-detail md:text-center">
					<h1 class="detail-tit md:text-20 md:mb-16">
						{{item.name_zh}}
					</h1>
					<div class="detail-li md:pt-16 md:border-t">
						<div class="detail-item">
							<span class="item-left">价格 :</span>
							<span class="item-right">{{ item.price }} USDT</span>
						</div>
						<div class="detail-item">
							<span class="item-left">状态 :</span>
							<span class="item-right">{{item.isHave?'已激活':'未激活'}}</span>
						</div>
					</div>
				</div>
			</div>
			<!-- //是否显示按钮 -->
			<template v-if="btnShow">
				<div class="claim-btn md:px-8 md:mt-32">
					<!-- 加载中 初始状态Loading -->
					<template v-if="initLoading">
						<a-button class="distable" type="primary" :loading="initLoading">
							Checking
						</a-button>
					</template>
					<template v-else>
						<a-button :class="{'distable': btnClass}" type="primary" @click="claim" :loading="isLoading">
							{{statusName}}
						</a-button>
					</template>
				</div>
			</template>
		</div>
	</a-modal>

</template>
<script>
	import { mintNft, isMinted } from '@/web3utils/base/WodBuy.js'
	import { enable } from "@/web3utils/base"
	import { Sub } from '@/utils/math.js'
	import { mapState } from 'vuex'
	export default {
		name: 'ClaimModal',
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			btnShow: {
				type: Boolean,
				default: true
			},
			item: {
				type: Object,
				default: () => {}
			},
			route: {
				type: String,
				default: ''
			},
		},
		data() {
			return {
				initLoading: false, //init加载
				isLoading: false, //按钮loading
				status: 1,
				isTwitter: 0,
				claimed: false,
			}
		},
		computed: {
			// <!-- 已刷新资产状态，BST勋章会稍后空投 -->
			...mapState(['addressinfo', 'address', 'sbtMedal', 'config805']),
			nowSBT() {
				return Number(this.sbtMedal[this.item['ID']])
			},
			startNum() {
				return {
					'Early Settlers Lv.1': this.addressinfo['v1star'],
					'Early Settlers Lv.2': this.addressinfo['v2star'],
					'Early Settlers Lv.3': this.addressinfo['v3star'],
				}
			},
			//按钮状态
			statusName() {
				if (!this.address) {
					return 'Connect wallet'
				}
				if (this.item.isHave) {
					return 'Activated'
				}
				return 'Activation'
			},
			btnClass() {
				// 登录且转发twitter
				if (this.address) {
					if (this.item.isHave) return true
				}
				return false
			},
		},

		watch: {
			address(val) {
				//没有地址时 关闭弹窗
				if (!val) {
					this.afterClose()
				}
			},
			visible(val) {
				if (val) {
					this.init();
				}
			}
		},
		async mounted() {

		},
		methods: {
			async init() {
				this.initLoading = true;
				// //查询是否已获取
				// const isMint = await isMinted(this.address,  this.item['BTcontract'], this.config805)
				// if (isMint > 0) {
				// 	this.isHave = true;
				// 	await getbstassert();
				// 	this.initLoading = false;
				// 	return
				// }
				// await getbstassert();
				this.initLoading = false;
			},
			afterClose() {
				this.$emit('closeClaim')
			},
			async claim() {
				if (!this.address) {
					this.goLogin();
				}
				if (this.item.isHave) return
					// this.$message.error(msg)
			},
			async goLogin() {
				//获取登录
				let enables = await enable();
				if (!enables.code) {
					// this.$message('Wallet not logged in')
					return;
				}
			},
		},
	}
</script>
<style lang="scss" scoped></style>
<style lang="scss">
	.blur-modal {
		.ant-modal {
			width: auto !important;
			max-width: none !important;

			.ant-modal-content {
				color: #fff;
				background-color: transparent;

				.ant-modal-body {
					padding: 0;

					.claim-modal {
						width: 860px;
						overflow: hidden;
						border-radius: 20px;
						border: 1px solid rgba(255, 255, 255, 0.20);
						background: #1A1A1D;
						backdrop-filter: blur(20px);
						padding: 40px;
						position: relative;

						.close {
							cursor: pointer;
							padding: 12px;
							position: absolute;
							top: 16px;
							right: 16px;

							img {
								width: 100%;
							}
						}

						.claim-content {
							display: flex;
							align-items: center;
							justify-content: space-between;

							/* gap: 40px; */
							.claim-img {
								width: 300px;
								min-width: 300px;
								max-width: 300px;
								margin-right: 40px;

								img {
									width: 100%;
									border-radius: 16px;
									border: 1px solid rgba(255, 255, 255, 0.10);
									object-fit: cover;
								}
							}

							.claim-img-md {
								display: none;
								width: 100px;

								img {
									width: 100%;
									border-radius: 16px;
									border: 1px solid rgba(255, 255, 255, 0.10);
									object-fit: cover;
								}
							}

							.claim-detail {
								.detail-tit {
									color: #FFF;
									font-size: 32px;
									font-style: normal;
									font-weight: 700;
									line-height: 32px;
									/* 100% */
									margin-bottom: 24px;
								}

								p {
									color: rgba(255, 255, 255, 0.60);
									font-size: 14px;
									font-style: normal;
									font-weight: 300;
									line-height: 20px;
									/* 142.857% */
									margin-bottom: 32px;
								}

								.detail-li {
									border-top: 0px solid #312D3A;
									display: flex;
									flex-direction: column;
									gap: 16px;

									.detail-item {
										display: flex;
										align-items: center;
										justify-content: space-between;

										span {
											font-size: 14px;
											font-style: normal;
											font-weight: 300;
											line-height: 14px;
											/* 100% */
										}

										.item-left {
											color: rgba(255, 255, 255, 0.60);
										}
									}
								}
							}
						}

						.progress-block {
							padding: 20px 0 0;

							p:nth-of-type(1) {
								margin: 0;
								background: var(--1, linear-gradient(270deg, #203DFD 0%, #34C5F3 100%));
								background-clip: text;
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;

								font-size: 16px;
								font-style: normal;
								font-weight: 600;
								line-height: 20px;
								/* 125% */
							}

							.progress-box {
								padding: 15px 0;
								position: relative;

								.roits {
									display: block;
									z-index: 2;
									position: absolute;
									top: 54%;
									transform: translateY(-50%);
									width: 14px;
									height: 14px;

									border-radius: 100%;
								}

								.left {
									left: 0;
									background: #34C5F3;
								}

								.right {
									right: 0;
									background: #fff;
								}


							}

							p:nth-of-type(2) {
								margin: 0;

								span:nth-of-type(1) {
									color: rgba(255, 255, 255, 0.80);
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: 12.207px;
									/* 76.293% */
								}

								span:nth-of-type(2) {
									color: #4F95FF;
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: 20px;
									/* 125% */
								}
							}
						}

						.claim-btn {
							margin-top: 48px;


							.ant-btn {
								width: 100%;
								height: 48px;
								cursor: pointer;
								display: flex;
								align-items: center;
								justify-content: center;
								overflow: hidden;
								border: unset;
								border-radius: 12px;
								background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
								display: inline-block;
								text-align: center;
								width: 100%;
								height: 100%;
								font-size: 16px;
								font-weight: 600;
								line-height: 48px;
							}

							.distable {
								cursor: no-drop;
								background: #242424 !important;
								color: #5A5A5A !important;
							}
						}

						@screen md {
							width: 337px;
						}
					}
				}
			}
		}
	}
</style>
<style lang="scss">
	.ant-rate {
		.ant-rate-star-second {
			svg {
				width: 15px;
				height: 15px;
			}
		}

		.ant-rate-star-full {
			.ant-rate-star-second {
				svg {
					color: rgba(51, 190, 244, 1) !important;
				}
			}
		}
	}
</style>
<template>
	<div class="sbt-item md:w-full" :data-isHave="item.isHave" v-if="isShowCard">
		<img src="https://s3.us-west-1.amazonaws.com/agi.love/img/isAcquire.png" class="isAcquire" alt="" srcset="" v-if="item.isHave" />
		<div class="nft-img h-281 md:w-full md:mb-12">
			<img :src="item.icon" alt="">
		</div>
		<div class="nft-name md:hidden flex-center">
			<img src="https://s3.us-west-1.amazonaws.com/agi.love/img/KT.png" class="isKt" alt="" srcset=""
				v-if="item.isAirdrop" />
			<span>{{item.name_zh}}</span>
		</div>
		<div class="detail-btn md:hidden">
			<div class="detail-box">
				<span>{{item.name_zh}}</span>
				<p>{{item.price}} USDT</p>
			</div>
			<div class="btn-box">
				<span @click="showClaim = true">{{item.isHave?'Activated':'Activation'}}</span>
			</div>
		</div>
		<div class="nft-name-md md:block">
			<div class="nft-detail-md">
				<h1 class="flex">
					<img src="https://s3.us-west-1.amazonaws.com/agi.love/img/KT.png" class="isKt" alt="" srcset="" />
					<span>{{item.name_zh}}</span>
				</h1>
				<p>{{item.price}} USDT</p>
			</div>
			<div class="nft-claim-md">
				<span @click="showClaim = true">{{item.isHave?'Activated':'Activation'}}</span>
			</div>
		</div>
		<ClaimModal :item="item" :visible="showClaim" @closeClaim="closeClaim"></ClaimModal>
		<modal v-model="isShow"></modal>
	</div>
</template>

<script>
	import modal from '@/components/modal.vue'
	import ClaimModal from '@/components/allMedalWall/ClaimModal.vue';
	import { mintNft, isMinted } from '@/web3utils/base/WodBuy.js'
	import { mapState } from 'vuex'
	export default {
		name: 'Name',
		components: { ClaimModal, modal },
		data() {
			return {
				isShow: false,
				isHave: false,
				showClaim: false,
			}
		},
		props: {
			item: {
				type: Object,
				default: () => {}
			},
			active: {
				type: String,
				default: 'all'
			},
		},
		computed: {
			...mapState(['isConnect', 'address', 'sbtMedal', 'config805']),
			isShowCard(){
				if(this.active == 'all'){
					return true
				}
				return false
			},
			nowId(){
				
			},
			//是否有资格
			nowSBT() {
				return Number(this.sbtMedal[this.item['ID']])
			},
		},
		mounted() {
			this.init()
		},
		methods: {
			async init() {
				const { type,id } = this.$route.query;
				if(id && id == this.item['ID']){
					this.showClaim = true
				}
			},
			openClaim() {
				this.showClaim = true
			},
			closeClaim(status) {
				this.showClaim = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	.sbt-item {
		width: 283px;
		/* height: 336px; */
		border-radius: 16px;
		border: 1px solid rgba(255, 255, 255, 0.10);
		background: #1A1A1D;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		position: relative;
		z-index: 0;

		.isAcquire {
			position: absolute;
			right: 5%;
			top: 5%;
			width: 38px;
			height: 38px;

			@screen md {
				width: 28px;
				height: 28px;

			}
		}

		.nft-img {
			border-radius: 16px;
			overflow: hidden;
			width: 281px;
			/* height: 281px; */
			/* min-height: 281px;
			max-height: 281px; */

			img {
				border-radius: 16px;
				width: 100%;
				/* height: 100%; */
				object-fit: cover;
			}
		}

		.nft-name {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 54px;

			.isKt {
				margin-right: 10px;
				width: 24px;
				height: 24px;
			}

			span {
				color: rgba(255, 255, 255, 0.80);
				text-align: center;
				font-size: 14px;
				font-style: normal;
				font-weight: 590;
				line-height: 14px;
				/* 100% */
				letter-spacing: -0.4px;
			}
		}

		.nft-name-md {
			display: none;
			color: #fff;

			.nft-detail-md {
				padding: 0 8px 8px;

				h1 {
					.isKt {
						margin-right: 10px;
						width: 24px;
						height: 24px;
					}

					color: rgba(255, 255, 255, 0.80);
					font-size: 14px;
					font-weight: 700;
					line-height: 16px;
					/* 100% */
					letter-spacing: -0.4px;
				}

				p {
					margin: 0;
					color: rgba(255, 255, 255, 0.80);
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					/* 133.333% */
					letter-spacing: -0.8px;
				}
			}

			.nft-claim-md {
				width: 100%;
				height: 36px;
				display: flex;
				align-items: center;
				justify-content: center;

				span {
					display: inline-block;
					width: 100%;
					height: 100%;
					text-align: center;
					line-height: 36px;
					border-radius: 0px 0px 16px 16px;
					border: 1px solid rgba(255, 255, 255, 0.10);
					background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);
					border: none;
				}
				.dis{
					background: rgba(36, 36, 36, 1) !important;
					color: rgba(90, 90, 90, 1);
				}
			}
		}

		.detail-btn {
			width: 100%;
			position: absolute;
			bottom: -142px;
			left: 0;
			z-index: 2;
			overflow: hidden;
			transition: all .3s;

			.detail-box {
				height: 100px;
				padding: 16px 12px;
				border: 1px solid rgba(255, 255, 255, 0.10);
				border-radius: 16px 16px 0px 0px;
				border-left: none;
				border-right: none;
				border-bottom: none;
				background: rgba(255, 255, 255, 0.09);
				backdrop-filter: blur(15px);

				span {
					color: #FFF;
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
					line-height: 14px;
					border: none;
					/* 100% */
				}

				p {
					margin: 0;
					margin-top: 10px;
					color: rgba(255, 255, 255, 0.80);
					font-size: 14px;
					font-style: normal;
					font-weight: 300;
					line-height: 18px;
					/* 128.571% */
				}
			}

			.btn-box {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 42px;
				border-radius: 0px 0px 16px 16px;
				background: linear-gradient(90deg, #031BDE 0%, #4C7CF9 100%);

				/* backdrop-filter: blur(15px); */
				span {
					display: inline-block;
					width: 100%;
					height: 100%;
					color: #FFF;
					text-align: center;
					font-size: 14px;
					font-style: normal;
					font-weight: 590;
					line-height: 42px;
					/* 100% */
					letter-spacing: -0.4px;
				}
				.dis{
					background: rgba(36, 36, 36, 1) !important;
					color: rgba(90, 90, 90, 1);
				}
			}
		}

		&:hover {
			.detail-btn {
				bottom: 0;
				/* transform: translateY(0); */
			}
		}
	}
</style>
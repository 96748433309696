const EarlySettlersLv1 = require('@/assets/lvPNG/EarlySettlersLv1.png')
const EarlySettlersLv2 = require('@/assets/lvPNG/EarlySettlersLv2.png')
const EarlySettlersLv3 = require('@/assets/lvPNG/EarlySettlersLv3.png')
const EarlySettlersLv4 = require('@/assets/lvPNG/EarlySettlersLv4.png')
const EarlySettlersLv5 = require('@/assets/lvPNG/EarlySettlersLv5.png')
const EarlySettlersLv6 = require('@/assets/lvPNG/EarlySettlersLv6.png')

export default {
	'01': {
		'level':'01',
		"contract": '',
		"name_zh": '初级会员',
		"name_en": 'Junior Membership',
		"price": "100",
		"icon": EarlySettlersLv1,
		"isStart": false,
		"isAirdrop": true,
    "isHave":false,
	},
  '02': {
		'level':'02',
		"contract": '',
		"name_zh": '中级会员',
		"name_en": 'Intermediate Membership',
		"price": "200",
		"icon": EarlySettlersLv2,
		"isStart": false,
		"isAirdrop": true,
    "isHave":false,
	},
  '03': {
		'level':'03',
		"contract": '',
		"name_zh": '高级会员',
		"name_en": 'Premium Membership',
		"price": "500",
		"icon": EarlySettlersLv3,
		"isStart": false,
		"isAirdrop": true,
    "isHave":false,
	},
  '04': {
		'level':'04',
		"contract": '',
		"name_zh": '白银会员',
		"name_en": 'Silver Membership',
		"price": "1000",
		"icon": EarlySettlersLv4,
		"isStart": false,
		"isAirdrop": true,
    "isHave":false,
	},
  '05': {
		'level':'05',
		"contract": '',
		"name_zh": '黄金会员',
		"name_en": 'Gold Membership',
		"price": "2000",
		"icon": EarlySettlersLv5,
		"isStart": false,
		"isAirdrop": true,
    "isHave":false,
	},
  '06': {
		'level':'06',
		"contract": '',
		"name_zh": '钻石会员',
		"name_en": 'Diamond Membership',
		"price": "5000",
		"icon": EarlySettlersLv6,
		"isStart": false,
		"isAirdrop": true,
    "isHave":false,
	},
}
import request from '@/utils/request';
import store from '@/store/index.js';
import { Balance } from './Users';

// 收益汇总
export const creditTotal = async () => {
    try {
        const res = await request({
            url: '/Trade/creditTotal',
            method: 'post',
        });
        return res;
    } catch (err) {
        return false;
    }
};
// 收益日明细
export const creditDay = async params => {
    try {
        const res = await request({
            url: '/Trade/creditDay',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
// 交易明细
export const getTradeList = async params => {
    try {
        const res = await request({
            url: '/Trade/getTradeList',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
// 收益日记录列表
export const creditDetail = async params => {
    try {
        const res = await request({
            url: '/Trade/creditdetal',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
// 交易K线
export const getKlinedataByEndtime = async params => {
    try {
        const res = await request({
            url: '/Trade/getKlinedataByEndtime',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
// 资金记录列表
export const GetUserBalanceRecord = async params => {
    try {
        const res = await request({
            url: '/Trade/GetUserBalanceRecord',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
// 提现申请记录列表
export const GetWithdrawApply = async params => {
    try {
        const res = await request({
            url: '/Trade/GetWithdrawApply',
            method: 'post',
            params,
        });
        return res;
    } catch (err) {
        return false;
    }
};
// swap 交易
export const swapsellAJToken = async params => {
    try {
        const res = await request({
            url: '/Trade/swapsellAJToken',
            method: 'post',
            params,
        });
        if (res.success) await Balance();
        return res;
    } catch (err) {
        return false;
    }
};
// 转账
export const Transfer_addr = async params => {
    try {
        const res = await request({
            url: '/Trade/Transfer_addr',
            method: 'post',
            params,
        });
        if (res.success) await Balance();
        return res;
    } catch (err) {
        return false;
    }
};
// 提现
export const WithdrawApply = async params => {
    try {
        const res = await request({
            url: '/Trade/WithdrawApply',
            method: 'post',
            params,
        });
        if (res.success) await Balance();
        return res;
    } catch (err) {
        return false;
    }
};
// 获取A池子信息
export const getPoolInfo = async () => {
    try {
        const res = await request({
            url: '/Trade/getPoolInfo',
            method: 'post',
        });
        return res;
    } catch (err) {
        return false;
    }
};
